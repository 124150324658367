<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <b-card>
      <PrebidPartnerToolbar
        v-if="Object.keys(prebidpartner).length !== 0"
        :prebidpartner="prebidpartner"
        closing
      />
    </b-card>
    <faq />
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { BCard } from 'bootstrap-vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import faq from './FaqPrebidPartner.vue'
import PrebidPartnerToolbar from './PrebidPartnerToolbar.vue'

export default {
  components: {
    Breadcrumbs,
    BCard,
    faq,
    PrebidPartnerToolbar,
  },
  data() {
    return {
      breadcrumbItems: [],
      prebidpartner: {},
    }
  },
  created() {
    this.getPrebidPartnerData()
  },
  methods: {
    async setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('Prebid partners'),
          to: '/prebidpartners/',
        },
        {
          text: this.prebidpartner.name,
          active: true,
        },
      ]
    },
    async getPrebidPartnerData() {
      const responseData = await useJwt.getPrebidPartner(this.$route.params.id)
      this.prebidpartner = responseData.data.prebidpartner || []
      this.setBreabcrumbs()
    },
  },
}
</script>
