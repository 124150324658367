<template>
  <div>
    <div class="d-flex align-items-center">
      <b-avatar
        rounded
        size="42"
        variant="light-info"
        class="mr-1"
      >
        <feather-icon
          icon="AlertCircleIcon"
          size="20"
        />
      </b-avatar>
      <div>
        <h4 class="mb-0">
          {{ $t('faq.title') }}
        </h4>
        <span>{{ $t('faq.description') }}</span>
      </div>
    </div>
    <app-collapse
      accordion
      type="margin"
      class="mt-2"
    >
      <app-collapse-item
        :is-visible="true"
        title="Question 1"
      >
        Answer 1
      </app-collapse-item>
      <app-collapse-item
        title="Question2"
      >
        Answer 2
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import {
  BAvatar,
  VBTooltip,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BAvatar,
    AppCollapseItem,
    AppCollapse,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    code: {
      type: String,
      default: '',
    },
  },
}
</script>
